import Vue from 'vue'
import Main from './Main.vue'
import router from './router'
import VueAnime from 'vue-animejs';
import VueRouter from 'vue-router';
import store from './store'
import 'animate.css/animate.min.css';

Vue.config.productionTip = false
export const bus = new Vue();


Vue.use(VueAnime)
Vue.use(VueRouter)

new Vue({
  render: h => h(Main),
  router,
  store,
}).$mount('#app')
