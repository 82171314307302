<template>
<div @scroll="onScroll" class="speech-wrapper">
    <div v-for="message in messages" :key="message.index">
        <Bubble 
        :sender_id="sender_id[message.sender]"
        :sender="message.sender"
        :content="message.content"
        :timestamp="message.datetime"/>
    </div>
    
    <div v-if="loading">
      loading...
    </div>
</div>
</template>

<script>
import Bubble from "./Bubble.vue"
import axios from "axios"

export default {
    data() {
        return {
            load_count: 50,
            messages: [],
            loading: false,
            sender_id: {
                "Whatsapp": 0,
                "Arno": 1,
                "Agathe": 2
            }
        }
    },
    created() {
        this.getNextMessages()
    },

    methods: {
        async getNextMessages() {

            // on call l'API et on recupere le resultat
            this.loading = true
            const endpoint =  this.$store.state.backend + "/conversation/messages"
            const result = await axios.get(
                endpoint,
                { 
                    params:  {
                        start: this.messages.length,
                        n: this.load_count
                    }
                }
            )
            const data = result.data
            for (var i = 0; i < data.length; i++) {
                this.messages.push(data[i])
            }
            this.loading = false
        },

        onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
            console.log("Scrolling")
            if (scrollTop + clientHeight >= scrollHeight - 10) {
                console.log("Reached bottom");
                this.getNextMessages();
            }
        }
    },

    components: {
        Bubble,
    },

}
</script>

<style>
.speech-wrapper {
    padding: 30px 40px;
    margin: auto;
    width: 320px;
    height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

</style>