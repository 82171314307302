<template>
  <div>
    <title>Liste du seau</title>
    <p id="bucketlist-title">Liste du seau</p>

    <div id="tasks">
      <InputField/>
      <Tasks/>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import InputField from "@/components/bucketlist/InputField"
import Tasks from "@/components/bucketlist/Tasks"

export const eventBus = new Vue();

export default {
  components: {
    InputField,
    Tasks,
  },

  created() {
    document.body.style.backgroundImage = "none"
    document.body.style.backgroundColor = '#D0D0D0'
  },

  methods: {
    onClickTask() {
      console.log("click");
    }
  }
}
</script>

<style>
body {
  background-color: #F7FAFC;
  display: flex;
  justify-content: center;
  margin-top: 10%;
  font-family: Arial, Helvetica, sans-serif;
}

main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

form {
  display: flex;
}

input[type=text] {
  padding: 16px 32px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
}

input::placeholder {
  font-size: 16px;
}

input:focus {
  outline: none;
}

input[id="task_submit"] {
  background-color: #1E1E2D;
  border: none;
  padding: 16px 32px;
  text-decoration: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

#bucketlist-title
{
  font-size: 30px;
  text-align: center;
  color:#1e1e2d;
}

.flex-container {
  display: flex;
}

a {
  color: #1E1E2D;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

p {
  font-size: 16px;
}
</style>
