<template>
    <img :src="src"/> 
</template>

<script>
export default {
    props : ["src", "index"],
    created () {
        
    }
}
</script>

<style>
img {
    object-fit: scale-down;
    justify-content: center;
    text-align: center;
    height: 500px;
    max-width: 800px;
}
</style>