<template>
  <div>
    <SsrCarousel show-arrows paginate-by-slide :slides-per-page='1'>
      <Slide v-for="(image, index) in images.slice().reverse()" 
      :src="image" 
      :key="index" />
    </SsrCarousel>
  </div>
</template>

<script>
import SsrCarousel from 'vue-ssr-carousel'
import ssrCarouselCss from 'vue-ssr-carousel/index.css'
import Slide from './Slide.vue'

// https://medium.com/dailyjs/leveraging-webpack-power-to-import-all-files-from-one-folder-cddedd3201b3


export default {
  components: {
    SsrCarousel,
    Slide,
  }, 
  created() {
        document.body.style.backgroundImage = "none"
        document.body.style.backgroundColor = "#f5d2ce"
      }, 
  computed: {
    images () {
      const path = require.context('@/assets/images', false, /^\.\/.*$/)
        console.log(path)
        console.log(path.keys().map(path))
        return path.keys().map(path)
      }
  }
}
</script>

<style>
</style>