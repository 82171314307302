<template>
    <div>
      <title>Slideshow</title>
      <p id="slideshow-title">
        Slideshow 
        <br/>
        <i id="slideshow-subtitle">couleur Somon</i>
        <pre class="letter">
          {{ fetchedLetterData }}
        </pre>
        <br/>
      </p>
      <Carousel class="carousel"/>
    </div>
</template>

<script>
import Carousel from '@/components/slideshow/Carousel'
import axios from "axios"


export default {
  components: {
    Carousel,
  },
  
  data() {
        return {
          fetchedLetterData: 'Loading...', // This will hold the fetched data
        }
  },

  methods: {
    async fetchLetter() {
      try {
        const endpoint = this.$store.state.backend + "/letter";
        const result = await axios.get(endpoint);

        // The response data is stored in the 'data' property of the result
        this.fetchedLetterData = result.data; // Directly access 'data' property for text content
      } catch (error) {
        console.error("Error fetching data:", error);
        this.fetchedLetterData = 'Failed to load data.';
      }
    },

    onClickTask() {
      console.log("click");
    }
  },

  mounted() {
      this.fetchLetter(); // Call the fetchLetter method when the component is mounted
  }
}
</script>

<style>
body {
  background-color: #fcf7fc;
  justify-content: center;
  margin: 0%;
  margin-left: 10%;
  margin-right: 10%;
  font-family: Arial, Helvetica, sans-serif;
}

main {
  display: flex;
  flex-direction: column;
  height: 100%;

}

form {
  display: flex;
}

input[type=text] {
  padding: 16px 32px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
}

input::placeholder {
  font-size: 16px;
}

input:focus {
  outline: none;
}

input[id="task_submit"] {
  background-color: #1E1E2D;
  border: none;
  padding: 16px 32px;
  text-decoration: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

#slideshow-title
{
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color:#ffffff;
}

#slideshow-subtitle
{
  font-size: 25px;
}

.flex-container {
  display: flex;
}

a {
  color: #1E1E2D;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

p {
    font-size: 16px;
}

.carousel {
  margin-top: 10px;
  justify-content: center;
  text-align: center;
  max-width: 600px;
}

.letter {
  font-size: 16px;
  justify-content: center;
  text-align: left;
  max-width: 800px;
  word-wrap: break-word;
  white-space: pre-wrap;
  color: rgb(75, 75, 75)
}
</style>
