import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state() {
    return {
      backend: "https://gougaback.arnoben.ovh"
      // backend: "http://localhost:5000"
    }
  }
})
