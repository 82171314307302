<template>
	<div class="flex-container">
		<span v-on:click="onClickTask" class="flex-child task">
			<span id="task" :class="currentClass">
					{{ content }}
			</span>
		</span>
		<span v-on:click="onClickDelete" class="flex-child delete">
			<span class="todo">
					X
			</span>
		</span>
		
	</div>
</template>

<script>
export default {
	props: {
		todo: {
			type: Boolean,
			default: true,
		},
		content: {
			type: String,
			default: ''
		},
		id: {
			type: String,
		}
	},

	computed: {
		currentClass() {
			return this.todo ? 'todo' : 'done'
		}
	},

	methods: {
		onClickTask() {
			var data = {
				id: this.id, 
				todo: this.todo, 
				content: this.content
			}
			this.$emit('event_taskClicked', data)
    	},
		onClickDelete() {
			var data = {id: this.id, todo: this.todo}
			this.$emit('event_taskDeleted', data)
    	}
	},
}
</script>

<style>
.flex-child {
	flex: 1;
}  

.flex-child.delete {
	flex: 0.1;
	margin-left: auto;
	text-decoration: none;
	border: none;
	display: flex;
	text-align: center;
	text-decoration: none;
	color:grey
}

.flex-child.delete:hover {
	background-color: #f3f3f3;
}

.todo {
	border: none;
	padding: 10px 30px;
	display: flex;
	text-decoration: none;
	cursor: pointer;
	font-size: 16px;
}
.todo:hover {
	background-color: #f3f3f3;
}


.done {
	border: none;
	padding: 10px 30px;
	display: flex;
	color: #9d9d9d;
	text-decoration: line-through;
}

.done:hover {
	background-color: #f3f3f3;
}


.done-delete-button{
	color: #9d9d9d;
}

#task {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
	padding-left: 20px;
	padding-right: 20px;
}
</style>