import Router from "vue-router"
import Conversation from '../pages/Conversation'
import Bucketlist from '../pages/Bucketlist'
import Slideshow from '../pages/Slideshow'

export default new Router({
    mode: 'history',
    routes: [
        { path: '/', component: Slideshow },
        { path: '/slideshow', component: Slideshow },
        { path: '/bucketlist', component: Bucketlist },
        { path: '/conversation', component: Conversation },
    ]
})