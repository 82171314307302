<template>

  <form onsubmit="return false">
    <input type="text" v-model="inputFieldContent" name="new_task" id="task" placeholder="Ecrire là">
    <input type="submit" id="task_submit" value="Mettre dans le seau" @click="onButtonClick">
  </form> 
    <!-- <input v-model="content" id="task" placeholder="Ecrire là" class="inputbox"/>
    <v-btn id="task_submit" value="Mettre dans le seau" class="inputbox"/> -->
    <!-- <input type="submit" id="task_submit" value="Mettre dans le seau"> -->
</template>

<script>
import { eventBus } from "@/pages/Bucketlist";

export default {
    props: ['content'],

    data() {
        return {
            inputFieldContent: "",
        }
    },

    methods: {
        onButtonClick() {
            if (this.inputFieldContent.toString() != ""){
                eventBus.$emit("new-task", this.inputFieldContent.toString());
                this.inputFieldContent = "";
            }
        }
    }
}
</script>

<style>
input[type=text] {
    padding: 16px 32px;
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    display: table-cell;
}

input::placeholder {
    font-size: 16px;
}

input:focus {
    outline: none;
}

input[id="task_submit"] {
    background-color: #1E1E2D;
    border: none;
    padding: 16px 32px;
    text-decoration: none;
    cursor: pointer;
    color: white;
    font-size: 16px;
}

.table {
    display: flex;
    flex-direction: row;
}

#task {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
	padding-left: 20px;
	padding-right: 20px;
}
</style>