<template>
  <div id="tasks">
    <div v-for="task in tasks_todo" :key="task.id">
      <Task
      @event_taskClicked="moveTask"
      @event_taskDeleted="deleteTask"
      :id="task.id"
      :todo="task.todo"
      :content="task.content"
      />
    </div>
    <div v-for="task in tasks_done" :key="task.id">
      <Task
      @event_taskClicked="moveTask"
      @event_taskDeleted="deleteTask"
      :id="task.id"
      :todo="task.todo"
      :content="task.content"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios"
import Task from "./Task.vue"
import { eventBus } from "@/pages/Bucketlist";

export default {
  components: {
    Task,
  },

  data() {
    return {
        tasks_todo: [],
        tasks_done: []
    }
  },

  created: function() {
    this.loadTasks();
    eventBus.$on("new-task", (data) => {
      this.onNewTask(data);
    });
  },

  watch: {
    tasks_todo() {
      this.save_tasks();
    },
    tasks_done() {
      this.save_tasks();
    }
  },

  methods: {
    async loadTasks() {
      const endpoint =  this.$store.state.backend + "/bucketlist/load_tasks"
      const result = await axios.get(endpoint)
      this.tasks_todo = result.data.todo
      this.tasks_done = result.data.done
    },
  
    async save_tasks() {
      const all_tasks = {
        'done': this.tasks_done,
        'todo': this.tasks_todo,
      }  
      const jsonData = JSON.stringify(all_tasks)
      const endpoint =  this.$store.state.backend + "/bucketlist/save_tasks"
      await axios.post(endpoint, jsonData)
    },

    onNewTask(content) {
      var task_id = Math.random().toString(36).slice(2, 12);
      console.log("received task containing: " + content + ', ' + task_id)
      var task = {
        id: task_id,
        content: content,
        todo: true,
      }
      this.tasks_todo.splice(0, 0, task)
    },

    moveTask(data) {
      this.deleteTask(data)
      data.todo = !data.todo
      if (data.todo) {
        this.tasks_todo.splice(0, 0, data)
      }
      else {
        this.tasks_done.splice(0, 0, data)
      }
    },

    deleteTask(data) {
      console.log('deleted post emit', data)
      const l = data.todo ? this.tasks_todo : this.tasks_done
      if (data.todo) {
        this.tasks_todo = l.filter(l => l.id != data.id)
      }
      else {
        this.tasks_done = l.filter(l => l.id != data.id)
      }
    },
  }
}
</script>

<style>
#tasks {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}
</style>