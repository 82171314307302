<template>
  <div>
    <p id="conv-title">Conversation WhatsApp</p>
    <SpeechWrapper/>
  </div>
</template>

<script>
import SpeechWrapper from "@/components/conversation/SpeechWrapper.vue"

export default {
  components: {
    SpeechWrapper,
  },

  created() {
    document.body.style.backgroundImage = "url('http://forums.crackberry.com/attachments/blackberry-10-wallpapers-f308/137432d1361639896t-z10-wallpaper-set-z10-music.jpg')"
  },
}
</script>

<style>
body{
  font-family: Arial, Helvetica, sans-serif;
}

#conv-title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: #178d48;
}
</style>
