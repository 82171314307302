<template>
<div class="buttonsContainer">
  <button @click='goToSlideshow' class="btnLink" id="slideshow" type="button">Slideshow</button>
  <button @click='goToBucketlist' class="btnLink" id="bucketlist" type="button">Bucketlist</button>
  <button @click='goToConversation' class="btnLink" id="btnConversation" type="button">Conversation</button>
</div>  
</template>

<script>
export default {
  methods : {
    goToSlideshow () {
      this.$router.push({ path: "/slideshow"})
    },

    goToBucketlist () {
      this.$router.push({ path: "/bucketlist"})
    },

    goToConversation () {
      this.$router.push({ path: "/conversation"})
    }
  }
}
</script>

<style>

.buttonsContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  gap:30px;
  text-shadow: 2px 2px;
}

.btnLink {
  background-color: #2c2c2cea;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  grid: 10%;
}
.btnLink:hover {
  background-color: #5353539c;
  cursor: pointer;
}
</style>