<template>
<!-- <div class="speech-wrapper"> -->
<div>
    <div class="bubble" :class="currentClass">
        <div class="txt">
            <p class="name" :class="currentClass">{{ this.sender }}</p>
            <p class="message">{{ this.content }}</p>
            <span class="timestamp">{{ this.formatDatetime }}</span>
        </div>
        <div class="bubble-arrow" :class="currentClass"></div>
    </div>
    <!--  TODO: Whatsapp automated message -->
</div>
</template>

<script>
export default {
    props: {
        sender_id: {
            required: true,
            type: Number,
            default: 1
        },
        sender: {
            required: true,
            type: String
        },
        content: {
            required: true,
            type: String
        },
        timestamp: {
            required: true,
            type: String
        },
    },
  
  computed: {
    formatDatetime() {
        const date_time = this.timestamp.split(' ');
        const date = date_time[0];
        const time = date_time[1].substring(0, date_time[1].length - 3);
        return time
    },
    currentClass() { 
        return this.sender_id === 1 ? '' : 'alt';
    }
  },

  methods: {
  }
}
</script>

<style>
.bubble {
    width: 270px;
    height: auto;
    display: block;
    background: #f5f5f5;
    border-radius: 4px;
    box-shadow: 2px 8px 5px #000;
    position: relative;
    margin: 0 0 10px 0;
    float: left;
}
.bubble.alt {
    margin: 0 0 10px 60px;
    float: right;
    background: #d7e7da;
}
.bubble .txt {
    padding: 8px 55px 8px 14px;
}
.bubble .txt .name {
    font-weight: 600;
    font-size: 15px;
    margin: 0 0 4px;
    color: #3498db;
}
.bubble .txt .name span {
    font-weight: normal;
    color: #b3b3b3;
}
.bubble .txt .name.alt {
    color: #178d48;
}
.bubble .txt .message {
    font-size: 15px;
    margin: 0;
    color: #2b2b2b;
}
.bubble .txt .timestamp {
    font-size: 14px;
    position: absolute;
    bottom: 8px;
    right: 10px;
    text-transform: uppercase;
    color: #999;
}
.bubble .bubble-arrow {
    position: absolute;
    width: 0;
    bottom: 42px;
    left: -16px;
    height: 0;
}
.bubble .bubble-arrow.alt {
    right: -2px;
    bottom: 40px;
    left: auto;
}
.bubble .bubble-arrow:after {
    content: "";
    position: absolute;
    border: 0 solid transparent;
    border-top: 9px solid #f5f5f5;
    border-radius: 0 20px 0;
    width: 15px;
    height: 30px;
    transform: rotate(145deg);
}

.bubble .bubble-arrow.alt:after {
    transform: rotate(45deg) scaleY(-1);
    border-top: 9px solid #d7e7da;
}
</style>